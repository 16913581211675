<template>
  <div
    class="interface-facture"
    :class="{ 'interface-simulation-facture': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- SELLECT TYPE DATE -->
        <div
          class="select-input-vuetify"
          v-if="checkPermission('GGDFTHFTDDDDF')"
        >
          <v-select
            v-model="type_date"
            :items="[
              { value: 'statue_date', text: 'statut de paiement' },
              { value: 'date_depot_dossier', text: 'date de dépôt' }
            ]"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Type de date"
            @change="handleChangeTypeDate"
            no-data-text="Aucun élément trouvé"
            :menu-props="{ bottom: true, offsetY: true }"
            color="#704ad1"
            item-color="#704ad1"
          ></v-select>
        </div>
        <!-- DATE DEBUT -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('GGDFTHFTDDDDF')"
        >
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                @click:clear="handleDateTimePicker(null, 'date_debut')"
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @change="handleDateTimePicker($event, 'date_debut')"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div
          class="date-input-vuetify mr-1"
          v-if="checkPermission('GGDFTHFTDDDDF')"
        >
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                @click:clear="handleDateTimePicker(null, 'date_fin')"
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker($event, 'date_fin')"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!--UPLOAD FILE-->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <input-file-excel-num-dossier
                v-show="true"
                :value="nums"
                @searchWithNumDossier="searchWithMultipleFacture"
                :fetchFuntion="fecthFactureNumbersInExcelFile"
                :showCount="true"
                hiddenContent="true"
              />
            </div>
          </template>
          <span>Choisir un fichier</span>
        </v-tooltip>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <v-progress-circular
          class="ml-2"
          v-if="getLoadingFraisDyn"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorFraisDyn" class="error">
            <ul v-if="Array.isArray(getErrorFraisDyn)" class="mb-0">
              <li v-for="(e, index) in getErrorFraisDyn" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorFraisDyn }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            v-if="checkPermission('GGDFTHCS')"
            hiddenContent="true"
          />
        </div>
        <!-- DELETE PROFORMA  -->
        <delete-proforma
          :dataToUse="getListProjectsFraisDync"
          :updateFunction="deleteProformaFraisAction"
          :permission="checkPermission('GGDFTHDFA')"
          @refreshData="handleFilter"
        />
        <!--AFFICHE LIGNE TOTAL-->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="block-icon mr-2"
              v-on="on"
              v-bind="attrs"
              @click="showTotal = !showTotal"
            >
              <font-awesome-icon :icon="showTotal ? 'eye-slash' : 'eye'" />
            </div>
          </template>
          <span>
            {{
              showTotal == true
                ? 'Masquer la ligne de total'
                : 'Afficher la ligne de total'
            }}
          </span>
        </v-tooltip>

        <!--REGLEMENT-->
        <reglement
          class="mr-1"
          v-if="checkPermission('GGDFTHAR') && computedCheckedRowsOneClient"
          :dataToUse="computedCheckedRowsOneClientFacture"
          :hiddenContent="true"
        />
        <!-- CONFIRMATION MULTIPLE -->
        <confirmation-multi
          v-if="checkPermission('GGDFTHCMF') && computedCheckedRowsMuli.length"
          @reloadData="reloadData"
          :dataToUse="computedCheckedRowsMuli"
        />
        <!-- GENERATE AVOIR  -->
        <generation-facture-avoir
          v-if="checkPermission('GGDFTHGFA')"
          :factureLibre="'false'"
          :idPermission="'GGDFTHGFA'"
          :interfaceName="this.$options.name"
          :dataToUse="computedCheckedRows"
          @RefreshData="reloadData"
          :permissionDate="checkPermission('SFTHDCA')"
        />

        <!--SEND MAIL-->
        <sendMail
          v-if="computedDataToSend.length > 0 && checkPermission('GGDFTHEM')"
          :dataToUse="computedDataToSend"
          @eventMailSendRefreshTable="handleFilter()"
          hiddenContent="true"
        />
        <!-- TRANSFERT FACTURE -->
        <transfert-facture-to-libre
          v-if="
            computedCheckRowsToTransfertLibre && checkPermission('GGDFTHTFAIFL')
          "
          :interfaceName="this.$options.name"
          :dataToUse="computedCheckRowsToTransfertLibre"
          :indexsToRemove="computedGetIndex"
          hiddenContent="true"
          type="support"
        />
        <!-- EXPORT DYNAMIC -->
        <export-dynamic
          v-if="checkPermission('GGDFTHEXLS') || checkPermission('GGDFTHEZP')"
          :filter="ComputedFilter"
          :selected="computedCheckRows"
          :exportXls="exportFactureDynamicXls"
          :exportZip="exportFactureDynamicZip"
          :famille="support"
          :zipRequired="checkPermission('GGDFTHEZP')"
          :xlsRequired="checkPermission('GGDFTHEXLS')"
          :hiddenContent="true"
        />
        <!-- FILTER -->
        <v-tooltip
          bottom
          color="#311B92"
          v-if="
            checkPermission('GGDFTHFMF') ||
              checkPermission('GGDFTHFV') ||
              checkPermission('GGDFTHFCL') ||
              checkPermission('GGDFTHFLL') ||
              checkPermission('GGDFTHSTAT') ||
              checkPermission('GGDFTHSTATIN')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- MASTER FILIALE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GGDFTHFMF')"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  v-model="isLocalStorageMasterFiliale"
                  :items="[...getListMasterFraisDync]"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListFraisDyn"
                  :persistent-placeholder="true"
                  label="Master Filiale"
                  required
                  return-object
                  item-text="name"
                  dense
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune Master filiale trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageMasterFiliale.length - 0 }} Master(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GGDFTHFV')"
                  v-model="isLocalStorageSupport"
                  :items="[...getListSupportFraisDync]"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListFraisDyn"
                  :persistent-placeholder="true"
                  label="Société"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageSupport.length - 0 }} société(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GGDFTHFCL')"
                  v-model="isLocalStorageFiliale"
                  :items="[...getListInstallagteurFraisDync]"
                  item-text="name"
                  item-value="id"
                  color="#704ad1"
                  item-color="#704ad1"
                  class="mb-1"
                  placeholder="Séléctionnez"
                  :loading="getLoaderDataFilterListFraisDyn"
                  :persistent-placeholder="true"
                  label="Client"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageFiliale.length - 0 }} client(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- FAMILLE -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('GGDFTHFLL')"
                  v-model="isLocalStorageFamile"
                  :items="getListFamilleConfigFrias"
                  item-text="familly"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                  hide-details
                  class="mb-4"
                  label="Famille"
                  :loading="getLoadingFamilleConfig"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
                <!-- STATUT FACTURE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GGDFTHSTAT')"
                  v-model="isLocalStorageStatut"
                  :items="computedValidationList"
                  item-text="full_name"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  item-color="#704ad1"
                  color="#704ad1"
                  label="Statut Facture"
                  multiple
                  :persistent-placeholder="true"
                  :loading="getLoaderDataFilterListFraisDyn"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                    </span>
                  </template>
                </v-autocomplete>

                <!-- STATUT INSTALLATEUR -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GGDFTHSTATIN')"
                  v-model="isLocalStorageStatutInstallateur"
                  :items="computedValidationListInstallateur"
                  item-text="full_name"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  item-color="#704ad1"
                  color="#704ad1"
                  label="Statut"
                  multiple
                  :persistent-placeholder="true"
                  :loading="getLoaderDataFilterListFraisDyn"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatutInstallateur.length - 0 }}
                      Statuts)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-simulation">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span v-if="type_date != null" class="item-filter-style"
            >Type de date : {{ type_date | FormateFilter }}</span
          >
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >

          <span v-if="filterFamilly != null" class="item-filter-style"
            >Famille : {{ filterFamilly.familly }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- MASTER FILIALE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterMasterFiliale.length"
          class="space-table-filter-gestion-global-fac"
        >
          <span class="title-block-chip-filter">Master filiale : </span>
          <v-sheet elevation="1" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.id"
                v-for="s in filterMasterFiliale"
                close
                @click:close="
                  remove(
                    s,
                    'filterMasterFiliale',
                    'handleFilter',
                    'isLocalStorageMasterFiliale'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterSupport.length"
          class="space-table-filter-gestion-global-fac"
        >
          <span class="title-block-chip-filter ml-3">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.id"
                v-for="v in filterSupport"
                close
                @click:close="
                  remove(
                    v,
                    'filterSupport',
                    'handleFilter',
                    'isLocalStorageSupport'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterFiliale.length"
          class="space-table-filter-gestion-global-fac"
        >
          <span class="title-block-chip-filter ml-3">Client : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.id"
                v-for="v in filterFiliale"
                close
                @click:close="
                  remove(
                    v,
                    'filterFiliale',
                    'handleFilter',
                    'isLocalStorageFiliale'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT INSTALLATEUR -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterStatutInstallateur.length"
          class="space-table-filter-gestion-global-fac"
        >
          <span class="title-block-chip-filter">Statut Installateur : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.full_name"
                v-for="s in filterStatutInstallateur"
                close
                @click:close="
                  remove(
                    s,
                    'filterStatutInstallateur',
                    'handleFilter',
                    'isLocalStorageStatutInstallateur'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.full_name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- STATUT FACTURE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="filterStatut.length"
          class="space-table-filter-gestion-global-fac"
        >
          <span class="title-block-chip-filter">Statut Facture : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.full_name"
                v-for="s in filterStatut"
                close
                @click:close="
                  remove(
                    s,
                    'filterStatut',
                    'handleFilter',
                    'isLocalStorageStatut'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.full_name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE FRAIS -->
      <div
        class="table-gestion-global-fac"
        :class="{
          'padding-table': returnTableFiltre
        }"
      >
        <v-data-table
          v-model="selectedTable"
          :headers="computedDFields"
          :items="getListProjectsFraisDync"
          :class="{
            'height-filter-chips-table': returnTableChips && !returnTableFiltre,
            'height-filter-table': returnTableFiltre && !returnTableChips,
            'height-filter-tous': returnTableChips && returnTableFiltre
          }"
          class="table-simulation-global"
          @item-expand="getListProjectsFraisDync"
          hide-default-footer
          show-expand
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="per_page"
          hide-action
          :no-data-text="
            getLoadingFraisDyn
              ? 'Chargement en cours ...'
              : `Il n'y a aucun enregistrement à afficher`
          "
        >
          <!-- HEADERS TABLE -->
          <template v-slot:[`header.check_all`]="{ header }">
            <td>
              <span v-if="header.value != 'check_all'">
                {{ header.label }}</span
              >
              <div v-else>
                <v-checkbox
                  class="checked-global"
                  v-model="checkAll"
                  @change="checkAllFunction"
                  color="#704ad1"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
          </template>
          <!-- BODY TABLE -->
          <template v-slot:[`item.check_all`]="{ item }">
            <td class="custom-cell sticky-header-1">
              <v-checkbox
                class="checked-groupe"
                v-model="item.check"
                @change="checkAllVerif(item)"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </td>
          </template>
          <!--ROWS DETAILS-->
          <template v-slot:[`item.data-table-expand`]="{ item }">
            <td class="custom-cell sticky-header-2">
              <button
                class="btn btn-anomalie"
                v-if="item && item.projects && item.projects.length > 0"
                @click="getDataForRow(item)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  icon="plus-square"
                  color="#704ad1"
                  title="Afficher dossiers"
                  v-if="item.detailsShowing == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  icon="minus-square"
                  color="#704ad1"
                  title="Masquer dossiers"
                  v-if="item.detailsShowing == true"
                />
              </button>
            </td>
          </template>
          <!--FILIALE INSTALLATEUR-->
          <template v-slot:[`item.name`]="{ item }">
            <td class="custom-cell sticky-body-three">{{ item.name }}</td>
          </template>
          <!--NUMERO DOSSIER-->
          <template v-slot:[`item.numero_dossier`]="{}">
            <td class="custom-cell">-</td>
          </template>
          <!--NOM ET PRENOM-->
          <template v-slot:[`item.nom_prenom`]="{}">
            <td class="custom-cell">-</td>
          </template>
          <!--montant min-->
          <template v-slot:[`item.min`]="{ item }">
            <td class="custom-cell">{{ item.montant_final }} €</td>
          </template>
          <!--MONTANT HT DOSSIER-->
          <template v-slot:[`item.montant_net_ht`]="{ item }">
            <td class="custom-cell">{{ item.montant_net_ht }} €</td>
          </template>
          <!--NUMERO FACTURE-->
          <template v-slot:[`item.numero_facture`]="{}">
            <td class="custom-cell">
              -
            </td>
          </template>
          <!--TOTAL NET HT-->
          <template v-slot:[`item.total_net_ht`]="{ item }">
            <td class="custom-cell">{{ item.total_net_ht }} €</td>
          </template>
          <!--SOCIETE-->
          <template v-slot:[`item.vendeur`]="{}">
            <td class="custom-cell">-</td></template
          >
          <!--CLIENT-->
          <template v-slot:[`item.client`]="{}">
            <td class="custom-cell">-</td></template
          >
          <!--MONTANT HT-->
          <template v-slot:[`item.ht`]="{ item }">
            <td class="custom-cell">{{ item.ht }} €</td></template
          >
          <!--MONTANT TTC-->
          <template v-slot:[`item.ttc`]="{ item }">
            <td class="custom-cell">{{ item.ttc }} €</td></template
          >
          <!--MONTANT TTC-->
          <template v-slot:[`item.total_tva`]="{ item }">
            <td class="custom-cell">{{ item.total_tva }} €</td></template
          >
          <!--DATE FACTURE-->
          <template v-slot:[`item.date_facture`]="{}">
            <td class="custom-cell">
              -
            </td></template
          >
          <!--RESTE-->
          <template v-slot:[`item.reste`]="{}">
            <td class="custom-cell">
              -
            </td></template
          >
          <!--STATUT FACTURE-->
          <template v-slot:[`item.statue_facture`]="{}">
            <td class="custom-cell">
              -
            </td></template
          >
          <!--STATUT INSTALLATEUR-->
          <template v-slot:[`item.statue_installateur`]="{}">
            <td class="custom-cell">
              -
            </td></template
          >
          <!--DATE PAIEMENT-->
          <template v-slot:[`item.date_paiement`]="{}">
            <td class="custom-cell">
              -
            </td></template
          >

          <!--MONTANT REGLE-->
          <template v-slot:[`item.reglement`]="{}">
            <td class="custom-cell">
              -
            </td>
          </template>
          <!--DATE ENVOI-->
          <template v-slot:[`item.date_envoi`]="{}">
            <td class="custom-cell">
              -
            </td>
          </template>

          <!--NUMERO LOT-->
          <template v-slot:[`item.numero_lot`]="{}">
            <td class="custom-cell">
              -
            </td>
          </template>
          <!--HISTORIQUE-->
          <template v-slot:[`item.historique`]="{}">
            <td class="custom-cell">
              -
            </td>
          </template>
          <!--NUMERO AVOIR-->
          <template v-slot:[`item.numero_avoir`]="{}">
            <td class="custom-cell">
              -
            </td>
          </template>
          <!-- EXPANDED TABLE -->
          <template #expanded-item="{ headers,item }">
            <td
              class="td-expanded"
              :colspan="headers.length"
              v-if="item.detailsShowing == true"
            >
              <table :items="item.projects" class="table-expanded">
                <tbody>
                  <tr
                    v-for="(data, index) in computedProjectsWithSepareteLine(
                      item.projects
                    )"
                    :key="item.name + index"
                    :class="{
                      'separate-line-style': data.type === 'separation'
                    }"
                  >
                    <td
                      class="td-expanded sticky-header-1"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <div
                        class="vertical-align"
                        v-if="data.type === 'separation'"
                      >
                        {{ data.count }}
                      </div>
                      <v-checkbox
                        v-else
                        class="checked-expanded"
                        v-model="data.check"
                        @change="checkAllVerifDetails(item, data)"
                        color="#704ad1"
                        :true-value="true"
                        :false-value="false"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <!-- VIDE -->
                    <td
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                      class="td-expanded sticky-header-2"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span> </span>
                    </td>
                    <!-- OBJET -->
                    <td
                      class="td-expanded sticky-body-three"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.objet }}
                    </td>
                    <!-- NUMERO DOSSIER -->
                    <td
                      v-if="checkPermission('GGDFTHACNDD')"
                      class="td-expanded"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.numero_dossier }}
                    </td>
                    <!-- NOM ET PRENOM -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACNEP')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.nom_prenom }}
                    </td>
                    <!-- MONTANT MIN -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACMM')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div class="flex-div">{{ data.montant_final }} €</div>
                    </td>
                    <!--MONTANT HT DOSSIER  -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACMHTD')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.montant_net_ht }} €
                    </td>
                    <!-- NUMERO FACTURE -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCNDF')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div class="flex-div">
                        {{ data.num }}
                        <VisualisationFacture
                          v-if="data.type !== 'separation'"
                          :awesome="true"
                          :facture="data.id"
                          :permission="checkPermission('GGDFTHTAF')"
                        ></VisualisationFacture>
                      </div>
                    </td>
                    <!-- AVOIR(s) -->
                    <td
                      v-if="checkPermission('GGDFTHCNFA')"
                      class="td-expanded"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div class="flex-div">
                        <AvoirFacture
                          v-if="
                            data.type_facture != 'Facture proforma' &&
                              data.etat != 'Annulée' &&
                              data.type !== 'separation'
                          "
                          :functionCreate="createAvoirFrais"
                          :item="data"
                          :permission="checkPermission('GGDFTHGDAP')"
                          :index="data.name + index"
                          :gettersData="produitsFactureFrais"
                          :methodProduct="getProductsFraisFacture"
                          :permissionDate="checkPermission('SFTHDCA')"
                        ></AvoirFacture>
                        <PopoverAvoirFacture
                          v-if="
                            data.FactureAvoir && data.FactureAvoir.length != 0
                          "
                          :factureAvoir="data.FactureAvoir"
                          :permissionTelecharger="checkPermission('GGDFTHTFA')"
                          :permissionVoirFacture="checkPermission('GGDFTHTAFA')"
                          type="support"
                        >
                        </PopoverAvoirFacture>
                      </div>
                    </td>
                    <!-- SOCIETE -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCV')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.vendeur }}
                    </td>
                    <!-- CLIENT -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACCL')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.client }}
                    </td>
                    <!-- COEIFFICIENT -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCCF')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.coef }}
                    </td>
                    <!-- MONTANT HT -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACMHT')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.ht ? data.ht : 0 }} €
                    </td>
                    <!-- TOTAL TVA -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACTVA')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.total_tva ? data.total_tva : 0 }} €
                    </td>
                    <!-- MONTANT TTC  -->
                    <td
                      style="width : 160px !important"
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACMTTC')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <TemplateFacture
                        :permissionDownloadFacture="
                          checkPermission('GGDFTHEFP')
                        "
                        :permissionDownloadFactureAvoir="
                          checkPermission('GGDFTHTFA')
                        "
                        :permissionValidationFacture="
                          checkPermission('GGDFTHVF')
                        "
                        :famille="'support'"
                        :getTemplateMethod="
                          getTemplateAndFiledsDataFraisDynamicToUpdate
                        "
                        :getterTemplate="templateFraisDyncData"
                        :validateMethod="uploadTemplateaFraisDynamic"
                        :item="data"
                        :project="data"
                        :componentName="$options.name"
                        :editable="
                          data.etat == 'Payé' ||
                          data.etat == 'Annulée' ||
                          data.modifiable == false
                            ? false
                            : checkPermission('SFTHVF')
                            ? true
                            : false
                        "
                        :download="data.etat == 'Annulée' ? false : data.id"
                      />
                    </td>
                    <!-- DATE FACTURE -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCDDF')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.date }}
                    </td>
                    <!-- RESTE A PAYER -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCRAP')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div v-if="data.type === 'separation'">-</div>
                      <div v-else>{{ data.reste }} €</div>
                    </td>
                    <!-- STATUT FACTURE -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCSDF')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div v-if="data.type === 'separation'">-</div>
                      <div v-else>
                        <EditableInput
                          v-if="data.type !== 'separation'"
                          :editable="
                            checkPermission('GGDFTHMSPF') &&
                              data.etat != 'Annulée' &&
                              data.etat != 'Payé' &&
                              data.type_facture != 'Facture proforma' &&
                              data.modifiable == true &&
                              false
                          "
                          champName="etat"
                          editableType="select"
                          :optionsSelect="computedValidationListComponent"
                          :item="{
                            item: data,
                            id_to_use: data.id
                          }"
                          :value="data.etat"
                          :updateFunction="updateStatueaFraisDynamic"
                          label="full_name"
                          :positionSelect="true"
                          formate="regieFacture"
                          :showIconEdit="true"
                          :showLabels="false"
                        />
                      </div>
                    </td>
                    <!-- STATUT INSTALLATEUR  -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCSI')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div v-if="data.type === 'separation'">-</div>
                      <div v-else>
                        <EditableInput
                          :editable="
                            checkPermission('GGDFTHMSIPF') &&
                              data.etat != 'Annulée' &&
                              data.etat != 'Payé' &&
                              data.statut_client != 'Payé' &&
                              data.type_facture != 'Facture proforma' &&
                              false
                          "
                          champName="statut_client"
                          editableType="select"
                          :optionsSelect="computedValidationListInstallateur"
                          :item="{
                            item: data,
                            id_to_use: data.id
                          }"
                          :value="data.statut_client"
                          :updateFunction="
                            updateStatueInstallateuraFraisDynamic
                          "
                          label="full_name"
                          :positionSelect="true"
                          formate="regieFacture"
                          :showIconEdit="true"
                          :showLabels="false"
                          classToUse="color-som-text"
                        />
                      </div>
                    </td>
                    <!-- DATE PAIEMENT -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCDP')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div v-if="data.type === 'separation'">-</div>
                      <div v-else>
                        {{ data.date_paiement }}
                      </div>
                    </td>
                    <!-- REGLEMENT -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCRG')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <div v-if="data.type === 'separation'">-</div>
                      <div v-else class="flex-div">
                        {{ data.montant_regle }} €
                        <PopoverReglementFacture
                          v-if="data.reglements.length"
                          :factureReglement="data.reglements"
                          :permissionModifier="checkPermission('GGDFTHUR')"
                          dataInterface="frais"
                        >
                        </PopoverReglementFacture>
                      </div>
                    </td>
                    <!-- DATE ENVOI -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHACDEM')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.email_sent_at }}
                    </td>
                    <!-- NUMERO DE LOT -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCNL')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      {{ data.lot }}
                    </td>

                    <!-- HISTORIQUE -->
                    <td
                      class="td-expanded"
                      v-if="checkPermission('GGDFTHCH')"
                      :class="{
                        'color-som-text': data.type === 'separation',
                        'separate-line-style': data.type === 'separation'
                      }"
                    >
                      <Historique
                        :awesome="true"
                        :index="data.id"
                        :permission="checkPermission('GGDFTHCH')"
                        :dataToUse="data.historique"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="footer-style-table">
      <div
        v-if="
          computedTotal &&
            (computedTotal.net_ht > 0 ||
              computedTotal.ttc > 0 ||
              computedTotal.someApaye > 0) &&
            checkPermission('GFMTHABT')
        "
        class="style-somme-th-ttc pl-2"
      >
        <div>
          <span class="bold-text"> Total HT:</span>
          {{ '   ' }}
          {{   computedTotal.ht
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
          € {{ '   ' }}
          <span class="bold-text">Total TTC:</span>
          {{ '   ' }}
          {{
            computedTotal.ttc
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          € {{ '   ' }}
          <span class="bold-text"> Total à payer : </span>
          {{ '   ' }}
          {{
            computedTotal.someApaye
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          €
        </div>
      </div>
      <div
        class="text-center pagination-ta
        ble"
      >
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'visionGlobaleFrais',
  data() {
    return {
      selectedTable: [],
      expanded: [],
      singleExpand: false,
      menu: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      factureToShow: null,
      triggersPop: 'hover',
      showTotal: true,
      support: 'support',
      checkAll: false,
      date_fin: null,
      date_debut: null,
      type_date: 'statue_date',
      filterFiliale: [],
      filterFamilly: null,
      filterMasterFiliale: [],
      filterStatut: [
        { value: 'A payer', full_name: 'A payer' },
        {
          value: 'Créé',
          full_name: 'Créé'
        },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ],
      // filterStatut: [],
      filterStatutInstallateur: [],
      searchValue: null,
      name: null,
      filterSupport: [],
      page: 1,
      per_page: 20,
      perPageList: [
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      nums: null,
      isLocalStorageStatutInstallateur: [],
      isLocalStorageSupport: [],
      isLocalStorageStatut: [],
      isLocalStorageMasterFiliale: [],
      isLocalStorageFiliale: [],
      isLocalStorageFamile: null,
      isLocalStorageTypeDate: null
    };
  },
  filters: {
    FormateFilter: value => {
      switch (value) {
        case 'statue_date':
          return 'statut de paiement';
        case 'date_depot_dossier':
          return 'date de dépôt';
      }
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingMailMasterFiliale',
      'getLoadingFraisDyn',
      'getErrorFraisDyn',
      'getListSupportFraisDync',
      'getLoaderDataFilterListFraisDyn',
      'getListInstallagteurFraisDync',
      'getListMasterFraisDync',
      'getListProjectsFraisDync',
      'getListProjectsFraisDyncRows',
      'getLoadingFamilleConfig',
      'getListFamilleConfigFrias',
      'isSuperAdminGetter',
      'checkPermission',
      'formateDateGetters',
      'getUserData',
      'getCategories',
      'getCategoriesDetailsLoading',
      'getShowNotifExportFile',
      'produitsFactureFrais',
      'templateFraisDyncData',
      'computedRouterLinkFacture',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.filterFiliale,
        this.filterStatutInstallateur,
        this.filterSupport,
        this.filterStatut,
        this.filterMasterFiliale
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null ||
        this.type_date !== null ||
        this.filterFamilly !== null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        (this.filterMasterFiliale && this.filterMasterFiliale.length) ||
        (this.filterSupport && this.filterSupport.length) ||
        (this.filterStatut && this.filterStatut.length) ||
        (this.filterStatutInstallateur &&
          this.filterStatutInstallateur.length) ||
        (this.filterFiliale && this.filterFiliale.length)
      ) {
        return true;
      }
      return false;
    },
    totalPages() {
      if (this.getListProjectsFraisDyncRows) {
        return Math.ceil(this.getListProjectsFraisDyncRows / this.per_page);
      }
      return this.getListProjectsFraisDyncRows;
    },
    computedStyle() {
      return this.showPopover == true ? 'd-none' : '';
    },
    computedPopover() {
      return this.showPopover == true ? 'click' : 'hover';
    },
    computedPopoverTriggers() {
      return this.triggersPop;
    },
    stylePopover() {
      if (this.computedPopoverTriggers == 'click') {
        return 'displayed-none';
      }
      return '';
    },
    computedCheckedRowsOneClient() {
      let tableReglement = [];
      let tableReglementCheck = [];
      let filiale_list = this.getUserData.filiales.map(item => item.id);
      if (
        this.getListProjectsFraisDync &&
        this.getListProjectsFraisDync.length
      ) {
        this.getListProjectsFraisDync.forEach(element => {
          element.projects.forEach(facture => {
            if (facture.check == true) {
              tableReglementCheck.push(facture);
            }
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type_facture != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
        return tableReglement.length == tableReglementCheck.length &&
          tableReglement.length > 0
          ? true
          : false;
      }
      return false;
    },
    computedCheckedRowsOneClientFacture() {
      let tableReglement = [];
      if (this.getListProjectsFraisDync.length) {
        let filiale_list = this.getUserData.filiales.map(item => item.id);
        this.getListProjectsFraisDync.forEach(element => {
          element.projects.forEach(facture => {
            if (
              facture.check == true &&
              facture.reste != '0' &&
              facture.type_facture != 'Facture proforma' &&
              (facture.etat == 'A payer' || facture.etat == 'Payé en partie') &&
              facture.belongsTo == true
            ) {
              if (tableReglement.length > 0) {
                if (filiale_list.includes(tableReglement[0].client_id)) {
                  if (
                    facture.vendeur_id == tableReglement[0].vendeur_id &&
                    filiale_list.includes(facture.client_id) &&
                    facture.client_id == tableReglement[0].client_id
                  ) {
                    tableReglement.push(facture);
                  }
                } else {
                  if (
                    facture.client_id == tableReglement[0].client_id &&
                    facture.vendeur_id == tableReglement[0].vendeur_id
                  ) {
                    tableReglement.push(facture);
                  }
                }
              } else {
                tableReglement.push(facture);
              }
            }
          });
        });
      }
      return tableReglement;
    },
    computedDFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          width: '40px !important',
          class: 'sticky-header-1',
          sortable: false
        },
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-2'
        },
        {
          value: 'name',
          text: 'Filiale Installateur',
          class: 'sticky-header-three',
          show: true
        },
        {
          value: 'numero_dossier',
          text: 'Numéro de dossier',
          show: this.checkPermission('GGDFTHACNDD')
        },
        {
          value: 'nom_prenom',
          text: 'Nom et prénom',
          show: this.checkPermission('GGDFTHACNEP')
        },
        {
          value: 'min',
          text: 'Montant MIN',
          show: this.checkPermission('GGDFTHACMM')
        },
        {
          value: 'montant_net_ht',
          text: 'Montant HT dossier',
          show: this.checkPermission('GGDFTHACMHTD')
        },
        {
          value: 'numero_facture',
          text: 'Numéro Facture',
          show: this.checkPermission('GGDFTHCNDF')
        },
        {
          value: 'numero_avoir',
          text: 'Avoir',
          show: this.checkPermission('GGDFTHCNFA')
        },
        {
          value: 'vendeur',
          text: 'Société',
          show: this.checkPermission('GGDFTHCV')
        },
        {
          value: 'client',
          text: 'Client',
          show: this.checkPermission('GGDFTHACCL')
        },
        {
          value: 'coeff',
          text: 'Coefficient',
          show: this.checkPermission('GGDFTHCCF')
        },
        {
          value: 'ht',
          text: 'Montant HT',
          show: this.checkPermission('GGDFTHACMHT')
        },
        {
          value: 'tva',
          text: 'Tva',
          show: this.checkPermission('GGDFTHACTVA')
        },
        {
          value: 'ttc',
          text: 'Montant TTC',
          show: this.checkPermission('GGDFTHACMTTC'),
          width: '160px !important'
        },
        {
          value: 'date_facture',
          text: 'Date de facture',
          show: this.checkPermission('GGDFTHCDDF')
        },
        {
          value: 'reste',
          text: 'Reste à payer',
          show: this.checkPermission('GGDFTHCRAP')
        },
        {
          value: 'statue_facture',
          text: 'Statut de facture',
          show: this.checkPermission('GGDFTHCSDF')
        },
        {
          value: 'statue_installateur',
          text: 'Statut installateur',
          show: this.checkPermission('GGDFTHCSI')
        },
        {
          value: 'date_paiement',
          text: 'Date de paiement',
          show: this.checkPermission('GGDFTHCDP')
        },
        {
          value: 'reglement',
          text: 'Reglement',
          show: this.checkPermission('GGDFTHCRG')
        },
        {
          value: 'date_envoi',
          text: "Date d'envoie",
          show: this.checkPermission('GGDFTHACDEM')
        },
        {
          value: 'numero_lot',
          text: 'Numéro de lot',
          show: this.checkPermission('GGDFTHCNL')
        },
        {
          value: 'historique',
          text: 'Historique',
          show: this.checkPermission('GGDFTHCH')
        }
      ];
      return fields.filter(item => item.show == true);
    },
    // formatedNumber() {
    //   return function(num) {
    //     returnNumber(num.toFixed(0)).toLocaleString() +
    //       '.' +
    //       Number(
    //         num.toString().slice(num.toString().indexOf('.') + 1)
    //       ).toLocaleString();
    //   };
    // },
    computedGetIndex() {
      let arrayOfIndex = [];
      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].projects.length; j++) {
          if (
            this.computedCheckedRows[i].projects[j].check == true &&
            this.computedCheckedRows[i].projects[j].avoir_id !== null
          ) {
            arrayOfIndex.push({
              indexOfChild: this.computedCheckedRows[i].projects[j].id,
              indexOfFather: this.computedCheckedRows[i].name
            });
          }
        }
      }
      return arrayOfIndex;
    },
    TopFilter() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 75 + heigthBlock;
      return 'top : ' + `${hei}` + 'px!important ;';
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 245 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        // table.forEach(element => {
        //   table2.push(element.clientHeight);
        // });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 40
          : 0;
      }
      let hei = 199 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckChipFilter() {
      if (
        this.filterStatutInstallateur.length > 0 ||
        this.filterStatut.length > 0 ||
        this.filterMasterFiliale.length > 0 ||
        this.filterFiliale.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedProjectsWithSepareteLine() {
      return function(data) {
        let arrayProjectWithseparate = [];
        let sommeHt = 0;
        let sommettc = 0;
        let sommeTva = 0;
        let j = 0;
        let index = 0;
        let count = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.numero_dossier === data[i + 1]?.numero_dossier) {
            arrayProjectWithseparate.push(data[i]);
            sommeHt += data[i].ht_float;
            sommettc += data[i].ttc_float;
            sommeTva += data[i].total_tva_float;
            j++;
            count++;
            data[i].index = index;
          } else {
            arrayProjectWithseparate.splice(j, 0, data[i]);
            sommeHt += data[i].ht_float;
            sommettc += data[i].ttc_float;
            sommeTva += data[i].total_tva_float;
            data[i].index = index;
            count++;
            j++;
            index++;
            arrayProjectWithseparate.splice(j, 0, {
              nom_prenom: data[i].nom_prenom,
              montant_final: data[i].montant_final,
              montant_net_ht: data[i].montant_net_ht,
              numero_dossier: data[i].numero_dossier,
              mode_paiement: '-',
              lot: '-',
              etat: '-',
              be_type: '-',
              avoir_id: '-',
              avoir_num: '-',
              client: '-',
              client_email: '-',
              date: '-',
              ht: (Math.round(sommeHt * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '),
              num: '-',
              objet: '-',
              project_id: '-',
              regie: '-',
              statut_client: '-',
              super_regie: '-',
              // index: '-',
              total_tva: (Math.round(sommeTva * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '),
              template_id: '-',
              ttc: (Math.round(sommettc * 100) / 100)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '),
              type_facture: '-',
              vendeur: '-',
              vendeur_id: '-',
              type_paiement: '-',
              type: 'separation',
              count: count
            });
            count = 0;
            j++;
            sommeHt = 0;
            sommettc = 0;
            sommeTva = 0;
          }
        }
        return arrayProjectWithseparate.filter(item =>
          this.showTotal == false ? item.type != 'separation' : item
        );
      };
    },
    computedTotal() {
      let someHt = 0;
      let someTtc = 0;
      let someApaye = 0;

      for (let i = 0; i < this.computedCheckedRows.length; i++) {
        for (let j = 0; j < this.computedCheckedRows[i].projects.length; j++) {
          if (
            this.computedCheckedRows[i].projects[j].check == true &&
            this.computedCheckedRows[i].projects[j].avoir_id === null
          ) {
            someHt =
              someHt +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].projects[j].ht
                      .replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            someTtc =
              someTtc +
                Math.round(
                  parseFloat(
                    this.computedCheckedRows[i].projects[j].ttc
                      .replace(' ', '')
                      .replace(',', '.')
                  ) * 100
                ) /
                  100 || 0;
            if (
              this.computedCheckedRows[i].projects[j].type_facture ==
                'Facture' &&
              (this.computedCheckedRows[i].projects[j].etat == 'A payer' ||
                this.computedCheckedRows[i].projects[j].etat ==
                  'Payé en partie' ||
                this.computedCheckedRows[i].projects[j].etat == 'Payé')
            ) {
              someApaye =
                someApaye +
                  Math.round(
                    parseFloat(
                      this.computedCheckedRows[i].projects[j].reste +
                        ''.replace(' ', '').replace(',', '.')
                    ) * 100
                  ) /
                    100 || 0;
            }
          }
        }
      }
      return {
        ht: Math.round(someHt * 100) / 100,
        ttc: Math.round(someTtc * 100) / 100,
        someApaye: Math.round(someApaye * 100) / 100
      };
    },
    computedCheckedRows() {
      let tableF = this.getListProjectsFraisDync?.filter(k => {
        return k.projects.some(e => e.check == true);
      });
      return tableF;
    },
    computedCheckedRowsMuli() {
      let tableF = this.getListProjectsFraisDync?.filter(k => {
        return k.projects.some(
          e =>
            e.check == true &&
            e.modifiable == true &&
            e.etat != 'Annulée' &&
            e.reglements.length == 0
        );
      });
      return tableF;
    },
    computedCreateLcr: function() {
      return function(data) {
        if (data) {
          if (
            this.checkPermission('GGDFTHMTP') &&
            data.modifiable == true &&
            data['etat'] != 'Payé' &&
            data['etat'] != 'Annulée' &&
            data['type_facture'] != 'Facture proforma'
          ) {
            return true;
          }
        }
        return false;
      };
    },
    computedValidationList() {
      return [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Annulée', full_name: 'Annulée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' },
        {
          value: 'Créé',
          full_name: 'Créé'
        }
      ];
    },
    computedValidationListComponent() {
      return [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Annulée', full_name: 'Annulée', $isDisabled: true },

        {
          value: 'Créé',
          full_name: 'Créé',
          $isDisabled: true
        }
      ];
    },
    computedValidationListInstallateur() {
      return [
        { value: 'A payer', full_name: 'A payer' },
        { value: 'Payé', full_name: 'Payée' },
        { value: 'Payé en partie', full_name: 'Payé en partie' }
      ];
    },
    computedOneSupport() {
      // return true;
      return this.filterSupport.length == 1;
    },
    ColorSupportComputed() {
      return function(support, principale) {
        if (support.bgColor && principale) {
          return {
            'background-color': support.bgColor + '!important',
            color: '#fff'
          };
        } else if (support.bgColor && !principale) {
          return { 'background-color': support.bgColor + '!important' };
        } else {
          return { 'background-color': 'f0f1ff !important' };
        }
      };
    },
    ComputedFilter() {
      return {
        date_fin: this.date_fin,
        date_debut: this.date_debut,
        filterMasterFiliale: this.filterMasterFiliale,
        filterSupport: this.filterSupport,
        filterFiliale: this.filterFiliale,
        filterFamilly: this.filterFamilly,
        filterStatut: this.filterStatut,
        filterStatutInstallateur: this.filterStatutInstallateur,
        search: this.searchValue,
        type_date: this.type_date
      };
    },
    computedCheckRows() {
      let selected = [];
      this.getListProjectsFraisDync.forEach(element => {
        element.projects.forEach(project => {
          if (project.check == true) {
            selected.push(project.id);
          }
        });
      });
      return selected;
    },
    computedDataToSend() {
      let selected = [];
      this.getListProjectsFraisDync.forEach(element => {
        element.projects.forEach(project => {
          if (
            project.check == true &&
            project.etat != 'Créé' &&
            project.modifiable == true &&
            project.type_facture != 'Facture proforma'
          ) {
            selected.push(project);
          }
        });
      });
      return selected;
    },
    computedCheckRowsToTransfertLibre() {
      let selected = [];
      this.getListProjectsFraisDync.forEach(element => {
        element.projects.forEach(project => {
          if (project.check == true && project.avoir_id !== null) {
            selected.push(project.id);
          }
        });
      });
      return selected;
    },
    computedValueTypeDateSelected() {
      return function(type) {
        this.computedValidationListInstallateur.map(item => {
          if (item.value === type) {
            return item.full_name;
          }
        });
      };
    }
  },
  methods: {
    ...mapActions([
      'getAllDynamicFactureFrais',
      'getAllDataForFilterDynamicFrais',
      'fetchAllFammilleConfig',
      'updateStatueInstallateuraFraisDynamic',
      'updateStatueaFraisDynamic',
      'updattypePaiementFraisDynamic',
      'getAllFactureForDynamicProject',
      'exportFactureDynamicZip',
      'exportFactureDynamicXls',
      'fetchAllCategories',
      'exportPdfFactureaFraisDynamic',
      'deleteProformaFraisAction',
      'fecthFactureNumbersInExcelFile',
      'displayFact',
      'avoirGlobaleFactureSci',
      'createAvoirFrais',
      'getProductsFraisFacture',
      'getTemplateAndFiledsDataFraisDynamicToUpdate',
      'uploadTemplateaFraisDynamic'
    ]),
    openPopover() {
      this.menu = true;
    },
    closePopover() {
      this.menu = false;
      //ORIGIIN FILTER
      this.filterStatut = [];
      this.filterMasterFiliale = [];
      this.filterSupport = [];
      this.filterFiliale = [];
      this.filterStatutInstallateur = [];
      this.filterFamilly = null;
      this.handleFilter(true);
      //POPOVER FILTER
      this.isLocalStorageMasterFiliale = [];
      this.isLocalStorageSupport = [];
      this.isLocalStorageFiliale = [];
      this.isLocalStorageStatutInstallateur = [];
      this.isLocalStorageStatut = [];
      this.isLocalStorageFamile = null;
    },
    validerPopover() {
      //
      //SET FILTER DROM POPOVER
      //
      this.filterMasterFiliale = this.isLocalStorageMasterFiliale;
      this.filterSupport = this.isLocalStorageSupport;
      this.filterFiliale = this.isLocalStorageFiliale;
      this.filterFamilly = this.isLocalStorageFamile;
      this.filterStatutInstallateur = this.isLocalStorageStatutInstallateur;
      this.filterStatut = this.isLocalStorageStatut;
      //
      //END SET FILTER DROM POPOVER
      //
      this.handleFilter(true);
      this.menu = false;
    },
    resetModal() {
      this.factureToShow = null;
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    async handleShowFacture(id) {
      this.$refs['visualisationFactureModal'].show();
      const response = await this.displayFact(id);
      if (response.success == true) {
        this.factureToShow = response.response;
      }
    },
    clickButton(id) {
      this.exportPdfFactureaFraisDynamic({
        id: id
      });
    },
    handleChangeTypeDate() {
      this.handleFilter();
    },
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    reloadData() {
      this.checkAll = false;
      this.handleFilter();
    },
    checkAllFunction() {
      this.getListProjectsFraisDync.map(item => {
        item.check = this.checkAll;
        item.projects.map(project => {
          project.check = this.checkAll;
        });
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      item.projects.map(project => {
        project.check = item.check;
      });
    },
    checkAllVerifDetails(data, item) {
      if (item.check == false && data.check == true) {
        data.check = false;
      }
      if (data.check == true && this.checkAll == false) {
        this.checkAll = true;
      }
    },
    checkAllVerifDetailsFacture(data, item, facture, index) {
      if (facture.check == false && data.check == true) {
        data.check = false;
      }
      if (facture.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      if (facture.check == false && item.check == true) {
        item.check = false;
      }
    },

    getDataForRow(data) {
      data.detailsShowing = !data.detailsShowing;
      if (data.detailsShowing == true) {
        if (!this.expanded.includes(data)) {
          this.expanded.push(data);
        }
      }
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    getAllDataForFilterDynamicFraisAction(item) {
      this.getAllDataForFilterDynamicFrais({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        masters: this.filterMasterFiliale.map(item => item.id),
        refreshMaster: item == 'master' ? false : true
      });
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.getAllDataForFilterDynamicFraisAction();
      this.filterFiliale = [];
      this.handleFilter();
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    setLocalStorageSimulationFraisFacture() {
      localStorage.setItem(
        'frais-dynamique',
        JSON.stringify({
          type_date: this.type_date,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filterFiliale: this.filterFiliale,
          filterFamilly: this.filterFamilly,
          filterMasterFiliale: this.filterMasterFiliale,
          search: this.searchValue,
          filterSupport: this.filterSupport,
          name: this.name,
          filterStatutInstallateur: this.filterStatutInstallateur,
          filterStatut: this.filterStatut
        })
      );
    },
    handleChangeCategory() {
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.handleFilter();
    },
    handleChangeMasterFiliale() {
      this.getAllDataForFilterDynamicFraisAction('master');
      this.handleFilter();
    },
    handleChangeSupport() {
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    // changeSearchNumValue(value) {
    //   this.name = value;
    //   this.handleFilter();
    // },
    handleFilter() {
      this.setLocalStorageSimulationFraisFacture();

      this.getAllDynamicFactureFrais({
        page: this.page,
        per_page: this.per_page,
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        search: this.searchValue,
        name: this.name,
        filterFiliale: this.filterFiliale,
        filterMasterFiliale: this.filterMasterFiliale,
        filterSupport: this.filterSupport,
        filterFamilly: this.filterFamilly,
        statut: this.filterStatut,
        statue_installateur: this.filterStatutInstallateur,
        type_date: this.type_date,
        nums: this.nums
      });
    },
    handlechangeFilialeSupport() {
      this.filterStatut = [];
      this.filterStatutInstallateur = [];
      this.handleFilter();
    },
    rowClass(item, type) {
      return 'ligneNormale';
    }
  },
  components: {
    VisualisationFacture: () =>
      import('@/views/component/Facture/VisualisationFacture.vue'),
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    sendMail: () => import('@/views/component/mail/sendMail.vue'),
    TemplateFacture: () =>
      import('@/views/component/Facture/TemplateFacture.vue'),
    confirmationMulti: () => import('./component/confirmationMulti.vue'),
    generationFactureAvoir: () =>
      import('./component/generationFactureAvoir.vue'),
    TransfertFactureToLibre: () =>
      import('@/views/component/Facture/TransfertFactureToLibre.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    ExportDynamic: () => import('./component/exportDynamic.vue'),
    reglement: () => import('@/views/component/reglement/reglement.vue'),
    Historique: () => import('@/views/component/Historique.vue'),
    AvoirFacture: () => import('@/views/component/Facture/AvoirFacture.vue'),
    PopoverAvoirFacture: () =>
      import('@/views/component/Facture/PopoverAvoirFacture.vue'),
    deleteProforma: () => import('./component/deleteProforma.vue'),
    PopoverReglementFacture: () =>
      import('@/views/component/Facture/PopoverReglementFacture.vue'),
    InputFileExcelNumDossier: () =>
      import(
        '@/views/ThProject/componentRapportTh/inputFileExcelNumDossier.vue'
      )
  },
  async mounted() {
    if (localStorage.getItem('frais-dynamique')) {
      let local = JSON.parse(localStorage.getItem('frais-dynamique'));
      this.date_debut = local.date_debut ? local.date_debut : null;
      this.date_fin = local.date_fin ? local.date_fin: null;
      this.filterMasterFiliale = local.filterMasterFiliale ? local.filterMasterFiliale :  [];
      this.isLocalStorageMasterFiliale =  this.filterMasterFiliale ;
      this.filterSupport = local.filterSupport ? local.filterSupport : [];
      this.isLocalStorageSupport =   this.filterSupport;
      this.filterFiliale = local.filterFiliale ? local.filterFiliale : [];
      this.isLocalStorageFiliale = this.filterFiliale ;
      this.filterFamilly = local.filterFamilly ? local.filterFamilly : null;
      this.isLocalStorageFamile = this.filterFamilly ;
      this.type_date = local.type_date ? local.type_date : null;
      this.filterStatutInstallateur = local.filterStatutInstallateur ? local.filterStatutInstallateur : [];
      this.isLocalStorageStatutInstallateur =  this.filterStatutInstallateur;
      this.filterStatut = local.filterStatut ?  local.filterStatut : [];
      this.isLocalStorageStatut =   this.filterStatut;
    } else {
      var now = moment();
      this.date_debut = moment(now)
        .subtract(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD');
      this.setLocalStorageSimulationFraisFacture();
    }

    this.fetchAllFammilleConfig({ blocked: false });
    this.fetchAllCategories();
    this.getAllDataForFilterDynamicFraisAction();
    this.handleFilter();
  }
};
</script>

<style scoped lang="scss">
.similation-facture-frais {
  .entete {
    padding: 5px 10px;
  }
  // .body-box-rapport-resize {
  // max-height: calc(100vh - (174 + var(--heigthBlock)) px) !important;
  // height: calc(100vh - (174 + var(--heigthBlock)) px) !important;
  // }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableSimulationFactureFrais {
      // max-height: calc(100vh - 220px) !important;
      // height: calc(100vh - 220px) !important;
      margin-bottom: 0px;
    }
    // .resizeTableSimulation {
    // max-height: calc(100vh - 220px) !important;
    // height: calc(100vh - 220px) !important;
    // }
  }
  .show-coef-button {
    background-color: green;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
  .show-montant-button {
    background-color: red;
    font-size: 14px;
    padding: 3px 12px 0px 12px;
  }
}
</style>
<style lang="scss">
.table-rapport-style .customTableSimulationFactureFrais td,
.customTableSimulationFactureFrais th {
  width: calc(100vh / 4);
  line-height: 10px;
  span {
    cursor: pointer;
  }
}

.table-rapport-style .customTableSimulationFactureFrais {
  .colhead-num-dossier-th,
  .col-num-dossier-th {
    left: 30px !important;
  }
  .thvalidation,
  .thvalidation {
    width: 50px !important;
  }
  td,
  th {
    font-size: 9px;
    width: 107px;
    min-width: 107px;
  }
  .btn-anomalie {
    padding: 0px;
  }
  tr.b-table-details > td {
    padding: 0px;
  }
  .background-week {
    justify-content: start !important;
    // background-color: #e0e0eb;
    // color: #fff;
    vertical-align: middle;
    td {
      // width: 100px;
      // min-width: 100px;
      padding: 8px 5px;
      margin: 0px;
      font-size: 9.5px;
      // border-right: 1px solid white;
    }

    .num-lot-td {
      display: grid;
      grid-template-columns: 20% 80%;
    }
    .item-details-style {
      border: 1px solid #fff !important;
      display: table-cell;
    }
  }
}
.sticky-header-three {
  position: sticky;
  left: 81px !important;
  z-index: 10 !important;
  top: 0px !important;
}
.blok-service-style {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.visibility-stiky-row-ssetails {
  background: #ededed;
  border: none !important;
}
.tr-vide {
  line-height: 25px;
}

.width-family-chip {
  width: auto !important;
}
.col_stiky_num_simulation {
  position: sticky;
  left: 30px;
}
.th_stiky_num_simulation {
  position: sticky;
  left: 30px;
  z-index: 20 !important;
}
.sticky-body-three {
  background-color: #fff;
  position: sticky;
  left: 80px;
  z-index: 1;
}
.space-table-filter-gestion-global-fac {
  padding-top: 0px;
}
.table-gestion-global-fac {
  &.padding-table {
    padding-top: 19px;
  }
}
</style>
